import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { transparentize } from 'polished'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'

import iconCreditCard from 'static/images/home/header/credit_card.svg'
import iconCrossCircle from 'static/images/home/header/cross_circle.svg'
import iconStarOctagram from 'static/images/home/header/star_octagram.svg'

import imageHelpCenter from 'static/images/home/help_center.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import imageDoubleArrowRightWhite from 'static/images/home/double_arrow_right_white.svg'

import imageCompanyKia from 'static/images/home/companies/kia.svg'
import imageCompanyLensa from 'static/images/home/companies/lensa.svg'
import imageCompanyOracle from 'static/images/home/companies/oracle.svg'
import imageCompanySonyMusic from 'static/images/home/companies/sony_music.svg'
import imageCompanyVodafone from 'static/images/home/companies/vodafone.svg'
import imageCompanyWix from 'static/images/home/companies/wix.svg'
import imageCompanyZillow from 'static/images/home/companies/zillow.svg'

import G2FeaturesComponent from 'components/G2FeaturesComponent'
import { lineHeight } from 'styled-system'
import CarouselVerticalSection from './components/CarouselVerticalSection'
import BlogItemShort from './components/BlogItemShort'
import CarouselReviewSection from './components/CarouselReviewSection'
import SocialMediasSection from './components/SocialMediasSection'
import SelectPlanButton from './components/SelectPlanButton'
import RequestDemoButton from './components/RequestDemoButton'
import HeaderImageAnimation from './components/HeaderImageAnimation'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledLatestWrapper = styled(Wrapper)`
  background: linear-gradient(180deg, #0250c9 0%, ${colors.primary} 100%);
`

const StyledHeaderCompaniesWrapper = styled(Flex)`
  opacity: 0.5;
`

const HEADER_FEATURE_ITEMS = [
  { icon: iconCreditCard, text: 'No credit card required' },
  { icon: iconStarOctagram, text: 'Free trial available' },
  { icon: iconCrossCircle, text: 'Cancel anytime' },
]

const HEADER_COMPANIES = [
  { key: 'Sony Music', image: imageCompanySonyMusic },
  { key: 'Wix', image: imageCompanyWix },
  { key: 'Zillow', image: imageCompanyZillow },
  { key: 'Oracle', image: imageCompanyOracle },
  { key: 'Vodafone', image: imageCompanyVodafone },
  { key: 'Lensa', image: imageCompanyLensa },
  { key: 'Kia', image: imageCompanyKia },
]

const query = graphql`
  {
    allWordpressPost(filter: { sticky: { eq: true } }, sort: { fields: [date], order: DESC }, limit: 3) {
      edges {
        node {
          id
          path
          title
          excerpt
          date
          modified
          slug
          sticky
          author {
            name
            avatar_urls {
              wordpress_96
            }
          }
          categories {
            slug
            name
            description
          }
          featured_media {
            title
            alt_text
            caption
            source_url
          }
        }
      }
    }
  }
`

// TODO:: convert carousel png images to webp
const Home = () => {
  return (
    <StaticQuery
      query={query}
      render={({ allWordpressPost: { edges } }) => {
        const articles = edges.map(({ node }) => node)
        return (
          <App fullHeader>
            <Fragment>
              <SEO
                title="The most complete social media management platform for brands and agencies"
                description="Expertly crafted social media management tools to help you exceed your goals"
              />

              <Wrapper>
                <Container
                  pl={{ mobile: 'l', tablet: 'l' }}
                  pr={{ mobile: 'l', tablet: 'l' }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex flexDirection="column" alignItems="center">
                    <Grid
                      mt="xl"
                      mb="m"
                      gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
                      gridGap="l"
                      width="100%"
                      alignItems="center"
                    >
                      <Flex width="100%" justifyContent="center" alignItems="center">
                        <Flex flexDirection="column">
                          <Flex width="100%" justifyContent="center" flexDirection="column">
                            <Flex
                              flexDirection={{ mobile: 'row', desktop: 'column' }}
                              flexWrap="wrap"
                              justifyContent="center"
                            >
                              <H1
                                fontSize="3xl"
                                fontWeight="bold"
                                color={COLOR_CONSTANTS.DENIM}
                                textAlign={{ mobile: 'center', desktop: 'left' }}
                              >
                                The most complete social media management platform for{' '}
                                <H1
                                  fontSize="3xl"
                                  fontWeight="bold"
                                  color="primary"
                                  textAlign={{ mobile: 'center', desktop: 'left' }}
                                  as="span"
                                >
                                  brands and agencies
                                </H1>
                              </H1>
                            </Flex>
                            <H2
                              mt="m"
                              pt="s"
                              pr={{ mobile: 0, desktop: 'l' }}
                              color="secondaryText"
                              textAlign={{ mobile: 'center', desktop: 'left' }}
                              fontWeight="normal"
                              fontSize="m"
                              lineHeight={lineHeights.xxl}
                            >
                              Unlock the full potential of social with most complete all-in-one social media management
                              platform. Scale your marketing efforts and crush your goals in ways you didn't know were
                              possible!
                            </H2>
                            <Grid
                              mt="l"
                              gridTemplateColumns={{ mobile: 'repeat(1, auto)', mobileLarge: 'repeat(3, auto)' }}
                              gridGap="m"
                              justifyContent={{ mobile: 'center', desktop: 'unset' }}
                              display={{ mobile: 'none', mobileLarge: 'grid' }}
                            >
                              {HEADER_FEATURE_ITEMS.map(({ icon, text }) => (
                                <Flex key={text} alignItems="center">
                                  <LocalImage src={icon} />
                                  <Text ml="s" fontSize="xs" color="secondaryText">
                                    {text}
                                  </Text>
                                </Flex>
                              ))}
                            </Grid>
                            <Flex
                              mt="l"
                              alignItems="center"
                              justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                              flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                            >
                              <SelectPlanButton />
                              <Flex ml={{ mobile: 0, mobileLarge: 'm' }}>
                                <RequestDemoButton />
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                        <HeaderImageAnimation />
                      </Flex>
                    </Grid>
                  </Flex>
                  <StyledHeaderCompaniesWrapper
                    alignItems="center"
                    flexWrap="wrap"
                    justifyContent={{ mobile: 'center', desktop: 'space-between' }}
                    my="l"
                  >
                    {HEADER_COMPANIES.map(({ key, image }) => (
                      <Flex key={key} mx={{ mobile: 'm', desktop: 0 }} my={{ mobile: 's', tablet: 0 }}>
                        <LocalImage src={image} alt={`Vista Social used by ${key}`} />
                      </Flex>
                    ))}
                  </StyledHeaderCompaniesWrapper>
                  <Flex mt="s" pt="s" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
                    <G2FeaturesComponent />
                  </Flex>
                </Container>
              </Wrapper>

              <CarouselVerticalSection />

              <SocialMediasSection />

              <CarouselReviewSection />

              <Wrapper>
                <Container
                  my="xl"
                  pl={{ mobile: 'l', tablet: 'l' }}
                  pr={{ mobile: 'l', tablet: 'l' }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex
                    justifyContent="space-between"
                    width="100%"
                    flexDirection={{ mobile: 'column', desktop: 'row' }}
                    alignItems="center"
                  >
                    <Flex flexDirection="column" width={{ mobile: '100%', tablet: '539px' }}>
                      <Text fontSize="l" color="secondaryText" textAlign="left">
                        Customer support
                      </Text>
                      <Text textAlign="left">
                        <Text fontSize="3xl" as="span" fontWeight="bold" color={COLOR_CONSTANTS.SALUTE}>
                          And we’re here
                        </Text>
                        <Text fontSize="3xl" as="span" fontWeight="bold" color="primary">
                          {' '}
                          to Help
                        </Text>
                      </Text>
                      <Text mt="m" pt="s" fontSize="l" color="secondaryText" textAlign="left">
                        Our customer advocates stand by 24/7 to support you via email and social media. We also have a
                        comprehensive, regularly updated help center for those who prefer to find help directly. If you
                        are feeling social, we have a supportive community of creators, small business owners, and
                        marketers on social media.
                      </Text>
                      <Flex mt="xl" alignItems="center">
                        <StyledLinkText as="a" href="https://support.vistasocial.com/" target="_blank" textAlign="left">
                          <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                            Visit our help center
                          </Text>
                          <LocalImage src={imageDoubleArrowRightBlue} />
                        </StyledLinkText>
                      </Flex>
                    </Flex>

                    <LocalImage
                      src={imageHelpCenter}
                      maxWidth="537px"
                      width="100%"
                      loading="lazy"
                      alt="Customer support"
                    />
                  </Flex>
                </Container>
              </Wrapper>

              <StyledLatestWrapper>
                <Container
                  my="xl"
                  pl={{ mobile: 'l', tablet: 0 }}
                  pr={{ mobile: 'l', tablet: 0 }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-end"
                    flexDirection={{ mobile: 'column', tablet: 'row' }}
                    px="l"
                  >
                    <Flex flexDirection="column">
                      <Text fontSize="3xl" color="white" textAlign="left">
                        Learn about the latest social media strategies,
                      </Text>
                      <Flex width="fit-content" bg={transparentize(0.8, colors.white)} borderRadius={radius.m}>
                        <Text fontSize="3xl" color="white" textAlign="left">
                          so you can test & iterate
                        </Text>
                      </Flex>
                    </Flex>
                    <StyledLinkText as="a" href="/insights" textAlign="left">
                      <Text fontSize="l" fontWeight="medium" textAlign="left" color="white" as="span">
                        READ MORE ARTICLES
                      </Text>
                      <LocalImage src={imageDoubleArrowRightWhite} />
                    </StyledLinkText>
                  </Flex>
                  <Flex mt="xl" flexWrap="wrap" justifyContent="center" px={{ mobile: 'm', tablet: '0' }}>
                    {articles.slice(0, 3).map((article, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <BlogItemShort key={index} {...article} index={index} />
                    ))}
                  </Flex>
                </Container>
              </StyledLatestWrapper>

              <Footer />
            </Fragment>
          </App>
        )
      }}
    />
  )
}

Home.propTypes = {
  pageContext: PropTypes.shape({
    filterData: PropTypes.object.isRequired,
    agencies: PropTypes.array,
    tools: PropTypes.array,
    events: PropTypes.array,
  }).isRequired,
}

export default Home
